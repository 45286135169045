import { useEffect } from 'react';
import useLatest from 'react-use/lib/useLatest';

import { type DtoDerivedVenueSettings } from '@lp-lib/api-service-client/public';

import { type Venue } from '../../types/venue';
import { ValtioUtils } from '../../utils/valtio';
import {
  useIsFirebaseConnected,
  useOptionalDatabaseRef,
} from '../Firebase/Context';
import {
  useVenue,
  useVenueAPI,
  useVenueDerivedSettings,
} from './VenueProvider';

export function useFirebaseVenueSyncing(writable: boolean) {
  const [venue] = useVenue();
  const derivedSettings = useVenueDerivedSettings();
  const latestVenue = useLatest(venue);
  const venueAPI = useVenueAPI();
  const firebaseConnected = useIsFirebaseConnected();
  const venueRef = useOptionalDatabaseRef<Nullable<Venue>>(
    `venues/${venue.id}/core`
  );
  const settingsRef = useOptionalDatabaseRef<Nullable<DtoDerivedVenueSettings>>(
    `venues/${venue.id}/derived-settings`
  );

  useEffect(() => {
    if (!firebaseConnected || !venueRef || !writable) return;
    venueRef.update(venue);
    venueAPI.log.debug('push venue to firebase', {
      venue: ValtioUtils.detachCopy(venue),
    });
  }, [venueRef, firebaseConnected, venue, writable, venueAPI]);

  useEffect(() => {
    if (!firebaseConnected || !settingsRef || !writable) return;
    if (!derivedSettings) {
      settingsRef.remove();
    } else {
      settingsRef.update(derivedSettings);
    }
    venueAPI.log.debug('push derived venue settings to firebase', {
      venue: ValtioUtils.detachCopy(derivedSettings),
    });
  }, [settingsRef, firebaseConnected, derivedSettings, writable, venueAPI]);

  useEffect(() => {
    if (
      !firebaseConnected ||
      !venue?.id ||
      !venueRef ||
      !settingsRef ||
      writable
    )
      return;
    venueRef.on('value', async (snap) => {
      const data = snap.val();
      if (!data) return;
      if (!data.updatedAt || data.updatedAt < latestVenue.current.updatedAt) {
        venueAPI.log.warn(
          'venue data from firebase is outdated, skip syncing.',
          {
            local: latestVenue.current,
            remote: data,
          }
        );
        return;
      }
      venueAPI.updateVenue(data);
      venueAPI.log.debug('sync venue from firebase', { venue: data });
    });
    settingsRef.on('value', async (snap) => {
      const data = snap.val();
      venueAPI.updateDerivedVenueSettings(data ?? null);
      venueAPI.log.debug('sync derived venue settings from firebase', {
        derivedVenueSettings: data,
      });
    });
    return () => {
      venueRef.off();
      settingsRef.off();
    };
  }, [
    venueRef,
    firebaseConnected,
    venue?.id,
    writable,
    latestVenue,
    venueAPI,
    settingsRef,
  ]);
}
